@import "../../variables";

section.not-found {
    color: #000;
    min-height: calc(100vh - 71px);
    text-align: center;

    h1 {
        width: 100%;
        font-size: 30px;
        text-transform: uppercase;
    }

    h2 {
        font-size: 20px;
    }
}