@import "./variables";

body {
    color: $cinza;
    font-family: 'Lato', sans-serif;
    margin: 0;
    font-size: 16px;

    .MuiButton-root {
        font-size: 12px;
        color: $vermelho-500;
    }

    .MuiButtonBase-root.ola {
        pointer-events: none;
        color: $laranja-400;
    }

    .bt.MuiButton-root {
        background: $cinza;
        color: #fff;
        font-size: 16px;
        padding: 5px 20px;
        border-radius: 15px;

        &.branco {
            background: #fff;
            color: $roxo-600;
            border-radius: 15px;

            &:hover {
                background: $vermelho-500;
                color: #fff;
            }
        }

        &:hover {
            background: $vermelho-500;
        }
    }

    .CookieConsent {
        background: $vermelho-500 !important;

        .MuiButtonBase-root {
            color: $amarelo-300;
            text-decoration: underline;
            margin-left: 15px;
        }

        #rcc-confirm-button {
            background: $amarelo-300 !important;
            color: $roxo-600 !important;
            border-radius: 7px !important;
            padding: 7px 15px !important;
            text-transform: uppercase;
            font-weight: 600;
            @include easeOut();

            &:hover {
                background: #fff !important;
            }
        }
    }
}

* {
    outline: none;
    box-sizing: border-box;
}

a,
a:hover,
a:focus {
    text-decoration: none;
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

.flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.flex-wr {
    flex-wrap: wrap;
}

.flex-ch {
    justify-content: center;
}

.flex-ri {
    justify-content: flex-end;
}

.flex-bv {
    align-items: flex-end;
}

.flex-le {
    justify-content: flex-start;
}

.flex-sp {
    justify-content: space-between;
}

.flex-sa {
    justify-content: last-baseline;
    padding-top: 50px;
}

.flex-cv {
    align-items: center;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}