@mixin exporOut {
    -webkit-transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

$cinza: #1d1d1d;
$roxo-100: #f3f3f3;
$roxo-600: #48373f;
$amarelo-300: #f7b64c;
$amarelo-400: #f8a941;
$vermelho-600: #c4111c;
$vermelho-500: #ce2f3e;
$laranja-400: #de533a;
$verde-400: rgb(64, 229, 64);