@import "../../variables.scss";

section.home {
    padding: 30px 0;

    h1 {
        font-weight: 800;
        margin-bottom: 7px;
    }

    .projetos {
        padding: 20px 0;
        gap: 15px;

        .card-projeto {
            width: 100%;
            background: rgba($color: $cinza, $alpha: 0.01);
            overflow: hidden;
            border: solid 1px rgba($color: $cinza, $alpha: 0.1);
            border-radius: 15px;
            margin-bottom: 20px;

            .esq {
                width: 70%;
                padding: 20px;

                h2 {
                    font-size: 28px;
                    font-weight: normal;
                    font-family: 'Monoton', cursive;
                    text-transform: uppercase;
                }

                h3 {
                    font-size: 18px;
                    font-weight: 300;
                    margin-bottom: 20px;
                }
            }

            .dir {
                background: url(../../assets/pixel-pattern.png);
                background-size: 2px 2px;
                width: 30%;
                box-shadow: 0 0 50px rgba($color: #000000, $alpha: 0.1);
                padding: 15px;

                img {
                    width: auto;
                    max-width: 200px;
                    max-height: 200px;
                    padding: 15px;
                    object-fit: contain;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    section.home .projetos .card-projeto {
        width: 100%;
        flex-flow: column-reverse;
    }

    section.home .projetos .card-projeto .dir img {
        object-fit: contain;
        max-width: 200px;
        max-height: 120px;
    }

    section.home .projetos .card-projeto .esq,
    section.home .projetos .card-projeto .dir {
        width: 100%;
    }

    section.home .projetos .card-projeto .esq h2 {
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 10px;
    }

    section.home .projetos .card-projeto .esq h3 {
        font-size: 16px;
    }
}