@import "../../variables";

header {
    background: url(../../assets/pixel-pattern.png);
    background-size: 2px 2px;
    padding: 10px 20px;
    font-size: 20px;
    text-align: center;
    position: sticky;
    top: 0;
    border-bottom: solid 1px rgba($color: $cinza, $alpha: 0.1);
    z-index: 99;

    a {
        color: $cinza;

        img {
            width: 50px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 15px;
        }
    }
}